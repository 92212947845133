// allows us to check urls at compile time for correct formatting
type ApiUrl = `/${string}/` | `/${string}/?${string}`;
type ApiUrls = Record<string, ApiUrl | ((...args: string[]) => ApiUrl)>;

const Endpoints = {
	// auth
	oidcLogin: '/oidc/login/', // no-creds
	oidcLogout: (slo: string): ApiUrl => `/oidc/logout/?slo=${slo}`, // creds
	oidcRefresh: '/oidc/refresh/', // creds

	// user
	user: '/api/v1/me/',

	// health check requests
	postRequest: '/api/v1/request/',
	getRequest: (page: string, rowsPerPage: string): ApiUrl =>
		`/api/v1/request/?page=${page}&page_size=${rowsPerPage}`,
	questions: (uuid: string): ApiUrl => `/api/v1/request/${uuid}/questions/`,
	contactInfo: (uuid: string): ApiUrl => `/api/v1/lookup_contact/${uuid}/`,
	submission: (uuid: string): ApiUrl =>
		`/api/v1/submit/${uuid}/review_submission/`,
	presignedPostUrl: '/api/v1/request/retrieve_presigned_post/',

	// meta
	backendDeploymentDetails: `/api/v1/deployment-details/`,
} satisfies ApiUrls;

export default Endpoints;
