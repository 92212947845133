import React, { type ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { toast as reactToast } from 'react-toastify';
import { Alert, Box } from '@ayx/onyx-ui';

import type { AlertSeverity } from 'types';

const { TOP_CENTER: TOP, BOTTOM_LEFT } = reactToast.POSITION;

interface ToastOptions {
	message: string;
	severity: AlertSeverity;
}

function ToastWrapper({ children }: { children: ReactNode }) {
	return <Box>{children}</Box>;
}

const buildToast = ({ message, severity }: ToastOptions) => {
	const isMobile = window.innerWidth < 600; // can't use hooks

	// Note: providing a custom id is one method of preventing duplication
	// see: https://fkhadra.github.io/react-toastify/prevent-duplicate

	reactToast(
		<ToastWrapper>
			<Alert severity={severity}>
				<Trans>{message}</Trans>
			</Alert>
		</ToastWrapper>,
		{
			type: severity,
			position: isMobile ? TOP : BOTTOM_LEFT,
			toastId: message,
		}
	);
};

/** @knipignore */
export const toastInfo = (message: string) => {
	buildToast({ message, severity: 'info' });
};

export const toastSuccess = (message: string) => {
	buildToast({ message, severity: 'success' });
};

/** @knipignore */
export const toastWarning = (message: string) => {
	buildToast({ message, severity: 'warning' });
};

export const toastError = (message: string) => {
	buildToast({ message, severity: 'error' });
};

/** @knipignore */
export const toast = (message: string, severity: AlertSeverity) => {
	buildToast({ message, severity });
};
